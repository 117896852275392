<template>
  <div class="d-lg-flex flex-column min-height-100">
    <div class="text-h6 font-weight-bold mb-4">
      Location
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          We determine historical followers' location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).
        </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft flex-grow-1">
      <v-card-text class="pb-0">
        <!-- show the options to switch -->
        <v-tabs
          show-arrows
          @change="(index) => selectedIndex = index"
        >
          <v-tab
            v-for="item in filteredItems"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>

        <!-- render the chart component -->
        <location-chart
          :data="series"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          small
          color="primary"
          @click="shouldShowDialog = true"
        >
          View More
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="shouldShowDialog"
      width="600"
      scrollable
    >
      <v-card>
        <v-card-title>
          Location By {{ capitalizeString(filteredItems[selectedIndex]) }}
        </v-card-title>

        <v-card-text class="make-it-scrollable">
          <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.text="{ item }">
              <div class="d-flex align-center">
                <v-img
                  height="13"
                  max-width="20"
                  :src="proxyUrl(`https://flagcdn.com/w20/${item.countryCode.toLowerCase()}.png`, true)"
                ></v-img>

                <span class="ml-3">
                  {{ item.text }}
                </span>
              </div>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import children components
const LocationChart = () => import(/* webpackChunkName: "charts-location" */ "@/blocks/user-profile/charts/Location")

// Export the SFC
export default {
  // Name of the component
  name: "AudienceLocation",

  // Accept incoming data from parent
  props: {
    platform: {
      type: String,
      required: true
    },

    allData: {
      type: Object,
      required: true
    },

    audienceData: {
      type: Object,
      required: true
    },

    totalValues: {
      type: Object,
      required: true
    },

    allTabs: {
      type: Array,
      required: true
    },

    selectedTab: {
      type: String,
      required: true
    }
  },

  // Register the components
  components: {
    LocationChart
  },

  // Define local data variables
  data: () => ({
    selectedIndex: 0,
    shouldShowDialog: false,
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Whether or not to show the location numerical values
     *
     * @returns {Boolean}
     */
    shouldHideLocationValueForTheUser() {
      return this.$store.getters["auth/profile"]?.email.includes("@x10.media")
    },

    /**
     * Whether or not the current profile's platform is youtube
     *
     * @returns {Boolean}
     */
    isYoutube() {
      return this.platform === "youtube"
    },

    /**
     * Based on the profile's platform, show the tab options
     *
     * @returns {Array}
     */
    filteredItems() {
      if (this.platform === "instagram") {
        return this.audienceData.state.length ? ["country", "state", "city"] : ["country", "city"]
      }
      else {
        return ["country"]
      }
    },

    /**
     * Get the table header options
     *
     * @returns {Array}
     */
    tableHeaders() {
      return [
        {
          text: "Location",
          value: "text",
          sortable: true,
          visible: true,
        },
        {
          text: "Followers",
          value: "followers",
          sortable: true,
          visible: !this.shouldHideLocationValueForTheUser,
        },
        {
          text: this.isYoutube ? "Comments" : "Engagements",
          value: this.isYoutube ? "comments" : "likes",
          sortable: true,
          visible: !this.shouldHideLocationValueForTheUser,
        },
      ].filter((item) => item.visible)
    },

    /**
     * Get the table row values
     *
     * @returns {Array}
     */
    tableItems() {
      const finalArray = []

      for (const tab of this.allTabs) {
        const itemName = this.filteredItems[this.selectedIndex]

        for (const iteratorItem of this.allData[tab][itemName]) {
          // check if an item with text is not already there, add a default one
          if (finalArray.findIndex((search) => search.text === iteratorItem.x) === -1) {
            finalArray.push({
              text: iteratorItem.x,
              countryCode: iteratorItem.countryCode,
              followers: "",
              comments: "",
              likes: ""
            })
          }

          // now find the item and update it
          const foundItem = finalArray.find((search) => search.text === iteratorItem.x)

          foundItem[tab] = `${numeral.fNumber( this.totalValues[tab] * iteratorItem.y / 100 )} / ${numeral.fPercent(iteratorItem.y)}`
        }
      }

      return finalArray
    },

    /**
     * Get the apex-chart series values
     *
     * @returns {Array}
     */
    series() {
      const mappedData = this.audienceData.state.length ? [
        this.audienceData.country,
        this.audienceData.state,
        this.audienceData.city
      ] : [
        this.audienceData.country,
        this.audienceData.city
      ]

      return mappedData[this.selectedIndex]
    }
  }
}
</script>
